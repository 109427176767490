@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_highlight.pcss';

.lia-link {
  display: block;
  justify-self: left;
  &:hover,
  &:focus {
    color: var(--lia-bs-body-color);
    text-decoration: none;
  }

  @media (--lia-breakpoint-down-sm) {
    justify-self: stretch;
  }
}

.lia-node {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    'icon title title'
    'icon content . '
    'icon footer . '
    '. children children'
    '. pager pager';

  &-children-pager-center {
    grid-template-areas:
      'icon title title'
      'icon content . '
      'icon footer . '
      '. children children'
      'pager pager pager';
  }
  @media (--lia-breakpoint-down-sm) {
    grid-template-areas:
      'icon title title'
      'icon content . '
      'icon footer . '
      'children children children'
      '. pager pager';

    &-children-pager-center {
      grid-template-areas:
        'icon title title'
        'icon content . '
        'icon footer . '
        '. children children'
        'pager pager pager';
    }
  }
  &-pager {
    grid-area: pager;
    margin: 25px 0 10px;
    &-center {
      justify-self: center;
    }
  }
  &-icon {
    grid-area: icon;
    margin-right: 15px;
  }
  &-title {
    grid-area: title;
    align-self: center;
    color: var(--lia-bs-body-color);
    font-size: var(--lia-bs-font-size-base);
    line-height: var(--lia-bs-line-height-base);
    margin-bottom: 0;
    word-break: break-word;
    font-weight: var(--lia-font-weight-md);
    &-sm {
      font-size: var(--lia-bs-font-size-sm);
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &-content {
    grid-area: content;
    color: var(--lia-bs-body-color);
    margin-top: 5px;

    &-sm {
      font-size: var(--lia-bs-font-size-sm);
    }
  }
  &-footer {
    grid-area: footer;
    margin-top: 5px;

    &-item {
      color: var(--lia-bs-text-muted);
    }
    &-md,
    &-sm {
      font-size: var(--lia-bs-font-size-sm);
    }
    &-xs {
      font-size: var(--lia-font-size-xs);
      margin-top: 0;
    }
  }
  &-children {
    grid-area: children;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 15px;

    &-indent-left {
      padding-left: 30px;
    }
    @media (--lia-breakpoint-down-sm) {
      margin-left: 10px;
    }
  }

  &-sm {
    .lia-node-content {
      font-size: var(--lia-bs-font-size-sm);
    }
    .lia-node-title {
      font-size: var(--lia-bs-font-size-sm);
    }
    .lia-lock {
      width: calc(var(--lia-bs-font-size-sm) * var(--lia-bs-line-height-base));
      height: calc(var(--lia-bs-font-size-sm) * var(--lia-bs-line-height-base));
    }
  }
  &-xs {
    .lia-node-content {
      font-size: var(--lia-font-size-xs);
    }
    .lia-node-title {
      font-size: var(--lia-bs-font-size-sm);
    }
    .lia-lock {
      width: calc(var(--lia-font-size-xs) * var(--lia-bs-line-height-base));
      height: calc(var(--lia-font-size-xs) * var(--lia-bs-line-height-base));
    }
  }
  :global {
    .lia-search-match-lithium {
      @include default-highlight();
    }
  }
}

.lia-unread-count {
  font-weight: var(--lia-bs-font-weight-bold);
}
